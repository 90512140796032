import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ComponentsModule } from './components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StoreModule } from '@ngrx/store';
import { quoteReducer } from './ngrx/reducers/quote.reducer';
import { formReducer } from './ngrx/reducers/insure-quote.reduce';
import { policyReducer } from './ngrx/reducers/policy.reduce';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { quoteMotorReducer } from './ngrx/reducers/motor-quote.reducer';
import { ticketReducer } from './ngrx/reducers/ticket-support.reducer';
import { HydrationEffects } from './ngrx/hydration/hydration.effect';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './ngrx';

@NgModule({
  declarations: [AppComponent, AuthLayoutComponent, AdminLayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      reducers, 
      { metaReducers }
    ),
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    EffectsModule.forRoot([HydrationEffects]),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
