import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { hydrationMetaReducer } from "./hydration/hydration.reducer";
import { formReducer } from "./reducers/insure-quote.reduce";
import { quoteMotorReducer } from "./reducers/motor-quote.reducer";
import { policyReducer } from "./reducers/policy.reduce";
import { quoteReducer } from "./reducers/quote.reducer";
import { ticketReducer } from "./reducers/ticket-support.reducer";
import { QuoteState } from "./states/quote.state";

export interface RootState {
    insureQuote : {form: {}};
    motorQuote : {};
    policyForm: {form: {},quoteId: "",userId:"",quoteDetails: "",documentToken:null};
    quote: QuoteState;
    ticket : {ticketSupport: {},}

}

export const reducers: ActionReducerMap<RootState> = {
  insureQuote : formReducer,
  motorQuote : quoteMotorReducer,
  policyForm: policyReducer,
  quote: quoteReducer,
  ticket: ticketReducer
}

export const metaReducers: MetaReducer[] = [
  hydrationMetaReducer
]


           