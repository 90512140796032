import { createAction, props } from '@ngrx/store';

export interface MotorDetails {
  id: number;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  engineCapacity: string;
  vehicleColour: string;
  vehicleNumber: string;
  vehicleDescription: string;
  vehicleUse: string;
  vehicleType: string;
  vehicleKey: string;
  vehicleName: string;
}

export interface Driver {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export interface Benefit {
  name: string;
  value: string;
  covered: boolean;
  magisId: string;
  mandatory: boolean;
}

export interface QuoteOption {
  motorDetails: MotorDetails;
  drivers: Driver[];
  benefits: Benefit[];
  premium: number;
  excess: number;
}

export interface Quote {
  id: number;
  userId: number;
  quoteId: number;
  quoteOptions: QuoteOption[];
  insuranceProvider: string;
  planName: string;
}

export const loadQuotes = createAction('[Quote API] Load Quotes');
export const loadQuotesSuccess = createAction(
  '[Quote API] Load Quotes Success',
  props<{ quotes: Quote[] }>(),
);
export const loadQuotesFailure = createAction(
  '[Quote API] Load Quotes Failure',
  props<{ error: any }>(),
);

export const addQuote = createAction(
  '[Quote API] Add Quote',
  props<{ quote: Quote }>(),
);
export const updateQuote = createAction(
  '[Quote API] Update Quote',
  props<{ quote: Quote }>(),
);
export const deleteQuote = createAction(
  '[Quote API] Delete Quote',
);

export const editBenefit = createAction(
  '[Quote API] Edit Benefit',
  props<{ quoteId: number; benefit: Benefit }>(),
);

export const editBenefitSuccess = createAction(
  '[Quote API] Edit Benefit Success',
  props<{ quoteId: number; benefit: Benefit }>(),
);

export const addItem = createAction(
  '[Form] Add Item',
  props<{ insureForm: any }>(),
);

export const deleteAll = createAction('[Form] Delete All');
